<template>
  <div>
    <FormBuildersList></FormBuildersList>
  </div>
</template>

<script>
import FormBuildersList from "../../components/formBuilders/FormBuildersList.vue";

export default {
  components: {
    FormBuildersList
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
</style>